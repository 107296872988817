<template>
  <div>
    <administrative-document-add></administrative-document-add>
  </div>
</template>

<script>
import AdministrativeDocumentAdd from "@/components/ecologist/classifiers/administrativeDocument/administrativeDocumentAdd";
export default {
  name: "administrativeDocumentAddView",
  components: { AdministrativeDocumentAdd },
};
</script>

<style scoped></style>
